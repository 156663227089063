import { doctorTypes } from "./actionTypes";

export const listDoctorsSuccess = (user) => ({
    type: doctorTypes.LIST_DOCTORS_SUCCESS,
    payload: user,
});

export const listDoctorsFailed = (error) => ({
    type: doctorTypes.LIST_DOCTORS_FAILED,
    error,
});


export const registerDoctorSuccess = (user) => ({
    type: doctorTypes.REGISTER_DOCTOR_SUCCESS,
    payload: user,
});

export const registerDoctorFailed = (error) => ({
    type: doctorTypes.REGISTER_DOCTOR_FAILED,
    error,
});

export const updateDoctorSuccess = (user) => ({
    type: doctorTypes.UPDATE_DOCTOR_SUCCESS,
    payload: user,
});

export const updateDoctorFailed = (error) => ({
    type: doctorTypes.UPDATE_DOCTOR_FAILED,
    error,
});

export const addDoctorScheduleSuccess = (user) => ({
    type: doctorTypes.ADD_DOCTOR_SCHEDULE_SUCCESS,
    payload: user,
});

export const addDoctorScheduleFailed = (error) => ({
    type: doctorTypes.ADD_DOCTOR_SCHEDULE_FAILED,
    error,
});

export const getDoctorScheduleSuccess = (user) => ({
    type: doctorTypes.ADD_DOCTOR_SCHEDULE_SUCCESS,
    payload: user,
});

export const getDoctorScheduleFailed = (error) => ({
    type: doctorTypes.ADD_DOCTOR_SCHEDULE_FAILED,
    error,
});
