
import { Button, Card, CardBody, CardHeader, Icon, Modal } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getDetailReservation, getReservationAdmin, getReservationByDoctor } from '../../services/reservation';

import { formatDate, formatReservationDateRange } from '../../utils/date';
import { getStorage } from '../../utils/auth';

export default function ListMyReservation() {
  const user = getStorage()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState({ status: '', email: '' });
  const [detailUuid, setDetailUuid] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [role, setRole] = useState(null)
  const [search, setSearch] = useState(null)

  useEffect(() => {
    if (String(user?.role?.[0]).toLowerCase() === 'master_admin') setRole('admin')
    else if (String(user?.role?.[0]).toLowerCase() === 'doctor') setRole('doctor')
    return () => { }
  }, [])


  const { data: myReservation, isFetched, isSuccess, isLoading, refetch } = useQuery(
    ["getReservation", limit, page],
    () => String(user?.role?.[0]).toLowerCase() === 'master_admin' ? getReservationAdmin(limit, page, search) : getReservationByDoctor(limit, page),
    {
      refetchOnWindowFocus: false,
      retry: 2
    }
  );

  const { data: detailReservation, isFetched: detailFetch, isSuccess: detailSuccess, isLoading: detailIsloading, refetch: detailRefatch } = useQuery(
    ["getDetail", detailUuid],
    () => getDetailReservation(detailUuid, role),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!detailUuid,
    }
  );

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value
    });
  };

  const modalClose = () => {
    setDetailUuid('')
    setShowModal(false)
  }
  useEffect(() => {
    if (detailReservation?.name) {
      setShowModal(true)
    }
  }, [detailReservation?.name])

  const handleShowModal = (uuid) => {
    setDetailUuid(uuid); // Mengatur UUID ke dalam state detailUuid
    // setShowModal(true);
  };



  return (
    <>
      <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
        <div className="container mx-auto max-w-full">
        </div>
      </div>

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <div className="flex flex-row items-center justify-between bg-teal-500 rounded-lg px-7 h-24 shadow-2xl -mt-10">
                <h2 className="text-white text-2xl w-{100} mr-4"> {/* Added 'mr-4' for right margin */}
                  Reservasi
                </h2>
              </div>


              <CardBody>
                <div className="overflow-x-auto">
                  <div className='flex text-right'>
                    <input
                      type="text"
                      name="name"
                      value={filter.name}
                      onChange={handleFilterChange}
                      className="appearance-none bg-transparent border-b border-teal-600 w-72 text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      placeholder='Cari dengan email atau nama'
                      autoFocus
                    />
                    <Button className="bg-orange-500 ml-2 mt-1">
                      Cari
                    </Button>
                  </div>
                  <table className="items-center table-responsive w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th className="w-1.5/6 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          No. Reservasi
                        </th>
                        <th className="w-1/6 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Name
                        </th>
                        <th className="w-1.5/6 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Email
                        </th>
                        <th className="w-1/6 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Status
                        </th>
                        <th className="w-1/6 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        myReservation?.map((item, index) => {
                          if (item?.status && item?.email) {
                            return (
                              <tr key={String(index)}>
                                <th className="w-1.5/6 border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-left">
                                  {item?.no_reservation}
                                </th>
                                <th className="w-1/6 border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-left">
                                  {item?.name}
                                </th>
                                <th className="w-1.5/6 border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-left">
                                  {item?.email}
                                </th>
                                <th className="w-1/6 border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-left">
                                  {item?.status}
                                </th>
                                <th className="w-1/6 border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-center">
                                  <button
                                    onClick={() => handleShowModal(item.uuid)}
                                    className="bg-orange-500 text-white font-normal py-1 px-4 rounded-full"
                                  >
                                    Detail
                                  </button>
                                </th>
                              </tr>
                            )
                          }
                        })}
                    </tbody>
                  </table>
                </div>

              </CardBody>
              {
                showModal && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center ">
                    <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                    <div className="container grid grid-cols-1 bg-white w-full mx-auto rounded shadow-lg z-50 overflow-y-auto">
                      {/* Konten Modal */}
                      <div className="modal-content py-4 text-left px-6 ">
                        {/* Tombol Tutup Modal */}
                        <div className="flex justify-end items-center">
                          <button
                            onClick={() => modalClose()}
                            className="text-gray-400 hover:text-gray-800 focus:outline-none"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                        <h3 className="text-2xl font-semibold mb-2">Detail</h3>
                        <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-10">
                          <div>
                            <p className='py-2'><strong>No. Reservation:</strong> {detailReservation?.no_reservation}</p>
                            <p className='py-2'><strong>Profile Name:</strong> {detailReservation?.name}</p>
                            <p className='py-2'><strong>Description:</strong> {detailReservation?.description}</p>
                            <p className='py-2'><strong>Email:</strong> {detailReservation?.email}</p>
                          </div>
                          <div>
                            <p className='py-2'><strong>Jadwal Janji:</strong> {formatReservationDateRange(detailReservation?.time_reservation_start, detailReservation?.time_reservation_end)}</p>
                            <p className='py-2'><strong>Terbuat pada:</strong> {formatDate(detailReservation?.created_at)}</p>
                            <p className='py-2'><strong>Status:</strong> {detailReservation?.status}</p>
                            {/* Add more fields as needed */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )

              }

            </Card>
          </div>
        </div>
      </div>

    </>
  );
}
