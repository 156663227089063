import StatusCard from 'components/StatusCard';
import { getAdminDashboardReservation, getDashboardReservation } from '../services/reservation';
import { useQuery } from 'react-query';
import { getStorage } from 'utils/auth';

export default function Dashboard() {
    const isUser = getStorage()
    let admin = String(isUser?.role?.[0]).toLocaleLowerCase() === 'master_admin'
    const { data: myReservation, isError, isFetched, isSuccess, isLoading, refetch } = useQuery(
        ["dashboard"],
        () => admin ? getAdminDashboardReservation() : getDashboardReservation(),
        {
          refetchOnWindowFocus: false,
          retry: 2
        }
      );

      console.log(myReservation?.data?.countAll.count, 'daa')

      if(isError){
        return 'Gagal mengambil data'
      }

    return (
        <>
            <div className="bg-orange-500 px-3 md:px-8 h-40" />

            <div className="px-3 md:px-8 -mt-24">
                <div className="container mx-auto max-w-full">
                    {/* <div className="grid grid-cols-1 xl:grid-cols-5">
                        <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                            <ChartLine />
                        </div>
                        <div className="xl:col-start-4 xl:col-end-6 px-4 mb-14">
                            <ChartBar />
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="px-3 md:px-8 h-auto -mt-28">
                <div className="container mx-auto max-w-full">
                    <div className="justify-between items-center grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 mb-4">
                        <StatusCard
                            color="pink"
                            icon="trending_up"
                            title="Total Konsultasi"
                            amount={myReservation?.data?.countAll.count}
                            // percentage="3.48"
                            // percentageIcon="arrow_upward"
                            // percentageColor="green"
                            // date="Since last month"
                        />
                        <StatusCard
                            color="orange"
                            icon="groups"
                            title="Konsultasi Berhasil"
                            amount={myReservation?.data?.countOngoing?.reservations}
                            // percentage="3.48"
                            // percentageIcon="arrow_downward"
                            // percentageColor="red"
                            // date="Since last week"
                        />
                        <StatusCard
                            color="purple"
                            icon="groups"
                            title="Konsultasi Pending"
                            amount={myReservation?.data?.countPending.count}
                            // percentage="1.10"
                            // percentageIcon="arrow_downward"
                            // percentageColor="orange"
                            // date="Since yesterday"
                        />
                        {/* <StatusCard
                            color="blue"
                            icon="poll"
                            title="Performance"
                            amount="49,65%"
                            percentage="12"
                            percentageIcon="arrow_upward"
                            percentageColor="green"
                            date="Since last month"
                        /> */}
                    </div>
                </div>
            </div>

            {/* <div className="px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 xl:grid-cols-5">
                        <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                            <PageVisitsCard />
                        </div>
                        <div className="xl:col-start-4 xl:col-end-6 px-4 mb-14">
                            <TrafficCard />
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}
