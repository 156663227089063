
const version = "/v1/"

const endpoint = {
    user_login: 'user' + version + 'login',
    check_profile: 'profile' + version + 'my-profile',
    user_register: 'profiles' + version + 'register',

    get_doctor: 'profile' + version + 'doctors/detail',
    list_doctors: 'profile' + version + 'doctors',
    register_doctor: 'user' + version + 'register-doctor',
    update_doctor: 'profile' + version + 'update-doctor',
    admin_update_doctor: 'profile' + version + 'admin/update-doctor',
    create_doctor_schedule: 'doctor/schedule' + version + 'create',
    get_doctor_schedule: 'doctor/schedule' + version + '?specialist_uuid=',


    list_layanan: 'master/services' + version,
    add_layanan: 'master/services' + version + 'create',
    update_layanan: 'master/services' + version,
    delete_layanan: 'master/services' + version

}

export default endpoint;
