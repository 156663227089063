export const userTypes = {
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_FAILED: "REGISTER_FAILED",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILED: "LOGIN_FAILED",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    SET_PROFILE: "SET_PROFILE",
    SET_DOCTER_UUID: "SET_DOCTER_UUID"
};


export const doctorTypes = {
    LIST_DOCTORS_SUCCESS: 'LIST_DOCTORS_SUCCESS',
    LIST_DOCTORS_FAILED: 'LIST_DOCTORS_FAILED',
    REGISTER_DOCTOR_SUCCESS: 'REGISTER_DOCTOR_SUCCESS',
    REGISTER_DOCTOR_FAILED: 'REGISTER_DOCTOR_FAILED',
    UPDATE_DOCTOR_SUCCESS: 'UPDATE_DOCTOR_SUCCESS',
    UPDATE_DOCTOR_FAILED: 'UPDATE_DOCTOR_FAILED',
    ADD_DOCTOR_SCHEDULE_SUCCESS: 'ADD_DOCTOR_SCHEDULE_SUCCESS',
    ADD_DOCTOR_SCHEDULE_FAILED: 'ADD_DOCTOR_SCHEDULE_FAILED'
    // Define action types related to the 'about' feature here
};

export const layananTypes = {
    LIST_LAYANAN_SUCCESS: "LIST_LAYANAN_SUCCESS",
    LIST_LAYANAN_FAILED: "LIST_LAYANAN_FAILED",
};
export const otherAction = {
    // Define action types for other parts of your application here
};