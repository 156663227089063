import { layananTypes } from "./actionTypes";

export const listLayananSuccess = (user) => ({
    type: layananTypes.LIST_LAYANAN_SUCCESS,
    payload: user,
});

export const listLayananFailed = (error) => ({
    type: layananTypes.LIST_LAYANAN_FAILED,
    error,
});


export const addLayananSuccess = (data) => ({
    type: layananTypes.REGISTER_DOCTOR_SUCCESS,
    payload: data,
});

export const addLayananFailed = (error) => ({
    type: layananTypes.REGISTER_DOCTOR_FAILED,
    error,
});