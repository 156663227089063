import api from '../utils/api';

export const getScheduleDoctorByAuth = async () => {
    try {
        const response = await api.get(`/doctor/schedule/v1/my-schedule`);
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};

export const getScheduleDoctorByAdmin = async (uuid) => {
    try {
        const response = await api.get(`/doctor/schedule/v1/list/${uuid}`);
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};

export const createScheduleDoctorAuth = async (payload) => {
    console.log("🚀 ~ file: scheduleService.js:23 ~ createScheduleDoctorAuth ~ payload:", payload)
    let user = payload?.doctor_uuid ? 'admin' : ''

    try {
        const response = await api.post(`/doctor/schedule/v1/${user}/create`, {
            ...payload
        });
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};
export const updateScheduleDoctorAuth = async (payload, uuid) => {
    let user = payload?.doctor_uuid ? 'admin' : 'doctor'
    try {
        const response = await api.put(`/doctor/schedule/v1/${user}/update/${uuid}`, {
            ...payload
        });
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};

export const deleteSchedule = async (uuid) => {
    try {

        const response = await api.delete(`/doctor/schedule/v1/delete/${uuid}`);
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};