import api from "../utils/api";

export const getReservationByDoctor = async (limit, page) => {
    try {
        const response = await api.get(`/reservation/v1/doctor?limit=${limit}&page=${page}`);
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};

export const getReservationAdmin = async (limit, page, search) => {
    try {
        // const response = await api.get(`/reservation/v1?limit=${limit}&page=${page}&search=${search}`);
        const response = await api.get(`/reservation/v1?limit=10&page=1`);
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};

export const getDetailReservation = async (uuid, role) => {
    let doctor = role === 'doctor' ? 'doctor/' : ''
    try {
        const response = await api.get('/reservation/v1/' + doctor + uuid);
        return role === 'doctor' ? response?.data : response?.data?.data
    } catch (error) {
        throw error;
    }
};
export const getDetailReservationAdmin = async (uuid) => {
    console.log("🚀 ~ file: reservation.js:23 ~ getDetailReservationDoctor ~ uuid:", uuid)
    try {
        const response = await api.get(`/reservation/v1/doctor/${uuid}`);
        return response?.data
    } catch (error) {
        throw error;
    }
};

export const getDashboardReservation = async (uuid) => {
    try {

        const response = await api.get(`/reservation/v1/dashboard`);
        return response?.data
    } catch (error) {
        throw error;
    }
};

export const getAdminDashboardReservation = async (uuid) => {
    try {

        const response = await api.get(`/reservation/v1/admin-dashboard`);
        return response?.data
    } catch (error) {
        throw error;
    }
};