import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import AdminNavbar from './AdminNavbar';
import Icon from '@material-tailwind/react/Icon';
import H6 from '@material-tailwind/react/Heading6';
import { getStorage } from 'utils/auth';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { logoutSuccess } from '../stores/actions/userAction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Swal from 'sweetalert2';

export default function Sidebar() {
    const dispatch = useDispatch()
    const [showSidebar, setShowSidebar] = useState('-left-64');

    const isUser = getStorage()
    let admin = String(isUser?.role?.[0]).toLocaleLowerCase() === 'master_admin'
    const handleLogout = () => {
        Swal.fire({
            title: "Logout",
            text: "Kamu yakin ingin keluar?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Keluar"
        }).then((result) => {
            if (result?.isConfirmed) {
                Cookies.remove("token")
                Cookies.remove("user")
                dispatch(logoutSuccess())
                window.location.replace('/')
            }
        });
    };

    return (
        <>
            <AdminNavbar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                    <a
                        href="https://material-tailwind.com?ref=mtd"
                        target="_blank"
                        rel="noreferrer"
                        className="mt-2 text-center w-full inline-block"
                    >
                        <H6 color="gray">Material Tailwind</H6>
                    </a>
                    <div className="flex flex-col">
                        <hr className="my-4 min-w-full" />

                        <ul className="flex-col min-w-full flex list-none">
                            <li className="rounded-lg mb-4">
                                <NavLink
                                    to="/"
                                    exact
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="dashboard" size="2xl" />
                                    Dashboard
                                </NavLink>
                            </li>
                            {admin ?
                                <>
                                    <li className="rounded-lg mb-2 ">
                                        <NavLink
                                            to="/doctors"
                                            className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                            activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                        >
                                            <Icon name="medication" size="2xl" />
                                            Dokter
                                        </NavLink>
                                    </li>
                                    <li className="rounded-lg mb-2 ">
                                        <NavLink
                                            to="/layanan"
                                            className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                            activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                        >
                                            <Icon name="list_alt" size="2xl" />
                                            Layanan
                                        </NavLink>
                                    </li>

                                </>
                                :
                                <li className="rounded-lg mb-2 ">
                                    <NavLink
                                        to="/schedule"
                                        className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                        activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                    >
                                        <Icon name="list" size="2xl" />
                                        Jadwal
                                    </NavLink>
                                </li>

                            }
                            {/* <li className="rounded-lg mb-2 ">
                                <NavLink
                                    to="/tables"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="toc" size="2xl" />
                                    Tables
                                </NavLink>
                            </li> */}
                            {/* <li className="px-4 rounded-lg mb-2 text-gray-700">
                                <a
                                    href="https://demos.creative-tim.com/material-tailwind-kit-react/#/login"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-4 text-sm font-light py-3"
                                >
                                    <Icon name="fingerprint" size="2xl" />
                                    Login
                                </a>
                            </li> */}
                            {/* <li className="px-4 rounded-lg mb-2 text-gray-700">
                                <a
                                    href="https://demos.creative-tim.com/material-tailwind-kit-react/#/register"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-4 text-sm font-light py-3"
                                >
                                    <Icon name="list_alt" size="2xl" />
                                    Register
                                </a>
                            </li> */}
                            {/* <li className="px-4 rounded-lg mb-2 text-gray-700">
                                <a
                                    href="https://demos.creative-tim.com/material-tailwind-kit-react/#/landing"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-4 text-sm font-light py-3"
                                >
                                    <Icon name="web" size="2xl" />
                                    Landing Page
                                </a>
                            </li> */}

                            <li className="rounded-lg mb-2 text-gray-700">
                                <NavLink
                                    to="/profile"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="account_circle" size="2xl" />
                                    Profile
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2 text-gray-700">
                                <NavLink
                                    to="/reservasi"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="book" size="2xl" />
                                    Reservasi
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2 ">
                                <NavLink
                                    to="#"
                                    onClick={handleLogout}
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-l cursor-pointer active:bg-teal-500 from-light-blue-500 to-light-blue-700"
                                    activeClassName=""
                                >
                                    <Icon name="logout" size="2xl" />
                                    Logout
                                </NavLink>
                            </li>
                            {/* <li className="rounded-lg mb-2 text-gray-700">
                                <NavLink
                                    to="/maps"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="map" size="2xl" />
                                    Maps
                                </NavLink>
                            </li> */}
                        </ul>


                    </div>
                </div>
            </div >
        </>
    );
}
