import StatusCard from 'components/StatusCard';
import { useDispatch, useSelector } from 'react-redux';
import { addLayanan, listLayanan } from '../services/layananService';
import { useEffect } from 'react';
import TableLayanan from '../components/TableLayanan'
import Swal from 'sweetalert2';
import { swal } from '../utils/alert';

export default function Layanan() {
    const dispatch = useDispatch()
    const data = useSelector(state => state.layanan.data)


    useEffect(() => {
        dispatch(listLayanan());
        return () => { }
    }, [dispatch]);

    const handleAdd = () => {
        Swal.fire({
            title: "Masukkan nama layanan",
            input: "text",
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Simpan",
            showLoaderOnConfirm: true,
            preConfirm: async (value) => {
                if (value?.length > 5) {
                    let result = await addLayanan({ "name": value })
                    if (result.success) {
                        swal.success('Layanan berhasil ditambahkan.')
                        dispatch(listLayanan());
                    } else if (result?.message?.includes(422)) {
                        swal.error('Nama layanan sudah pernah digunakan.')
                    } else {
                        swal.error('Layanan gagal ditambahkan, coba lagi beberapa saat kemudian.')
                    }
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })

    }


    const handleSubmit = (req, res) => {

    }

    return (
        <>
            <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                </div>
            </div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableLayanan data={data} handleAdd={handleAdd} />
                    </div>
                </div>
            </div>
        </>
    );
}
