import Cookies from 'js-cookie';

export const getUserLocalStorage = () => {
    return Cookies.get('user');
}
export const getStorage = () => {
    try {
        const token = Cookies.get('user');
        return JSON.parse(token)
    } catch (error) {
        return null
    }
}

export const getToken = () => {
    try {
        const token = Cookies.get('token');
        return JSON.parse(token)
    } catch (error) {
        return null
    }
}

export const setUserLocalStorage = (user) => {
    localStorage.setItem('user', JSON.stringify(user))
}

export const setTokenLocalStorage = (token) => {
    Cookies.set('token', token);
}

export const decoreateUser = () => {
    try {
        const user = Cookies.get('user');
        if (user) {
            return JSON.parse(user);
        } else {
            return null;
        }
    } catch (error) {
        return null
    }
}
