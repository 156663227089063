
import { Button, Card, CardBody, Icon } from '@material-tailwind/react';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { createScheduleDoctorAuth, deleteSchedule, getScheduleDoctorByAuth, updateScheduleDoctorAuth, getScheduleDoctorByAdmin } from '../../services/scheduleService';
import { getMyLayanan } from '../../services/doctorService';
import { getStorage } from '../../utils/auth';
import { swal } from '../../utils/alert';

export default function DoctorSchedule(props) {
  let uuid = props?.match?.params.id

  const user = getStorage()
  const [itemSelected, setItemSelected] = useState(null);
  const [onEdit, setOnEdit] = useState(false);
  const [idSchedule, setIdSchedule] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const buttonRef = useRef(null);
  const contentRef = useRef(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    start_time: '',
    end_time: '',
    time: '',
    day: '',
    available: ['TATAP MUKA'],
    service_uuid: '',
    is_active: true
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (
      contentRef.current &&
      !contentRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };
 

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(async () => {
    // Attach the event listener when the component mounts
    if (idSchedule) {
      if (onEdit) {
        setModalOpen(true)
      } else {
        let result = await swal.remove('')
        console.log("🚀 ~ file: listScheduleDoctor.js:58 ~ useEffect ~ result:", result)
        if (result) {
          handleDelete()
        }
      }
    } else {
      setOnEdit(null)
    }
    // Clean up the event listener when the component unmounts
    return () => { };
  }, [idSchedule]);

  const { data: mySchedule, isFetched, isSuccess, isLoading, refetch } = useQuery(
    "getMySchedule",
    () => uuid ? getScheduleDoctorByAdmin(uuid) : getScheduleDoctorByAuth(),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      onSuccess: (res) => {
      },
    }
  );


  const { data: layanan, isFetched: layananFetch, isSuccess: layananSuccess, isLoading: layananLoad, isError } = useQuery(
    "getLayanan",
    () => getMyLayanan(uuid),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!mySchedule
    }
  );

  console.log("🚀 ~ file: listScheduleDoctor.js:83 ~ ListDoctorSchedule ~ layanan:", layanan)
  const closeModal = () => {
    setModalOpen(false);
    setOnEdit(false)
    setIdSchedule(null)
    setFormData({
      start_time: '',
      end_time: '',
      time: '',
      day: '',
      available: [],
      service_uuid: '',
      is_active: true
    })
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Jika elemen adalah checkbox, kelola sebagai array of selected values
    if (type === "checkbox") {
      const newValue = [...formData.available]; // Buat salinan array 'available'

      if (checked) {
        newValue.push(value); // Tambahkan nilainya jika dicentang
      } else {
        // Hapus nilainya jika tidak dicentang
        const index = newValue.indexOf(value);
        if (index !== -1) {
          newValue.splice(index, 1);
        }
      }

      setFormData({
        ...formData,
        available: newValue, // Perbarui 'available' dengan nilai yang telah diperbarui
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }

  }

  const handleSubmit = () => {

    console.log("🚀 ~ file: listScheduleDoctor.js:144 ~ handleSubmit ~ formData:", formData)
    console.log("🚀 ~ file: listScheduleDoctor.js:144 ~ handleSubmit ~ formData:", formData.profile_uuid)
    if (!formData.start_time) setErrorMessage('Form waktu mulai tidak boleh kosong!')
    else if (!formData.end_time) setErrorMessage('Form waktu berakhir tidak boleh kosong!')
    else if (!formData.day) setErrorMessage('Form hari tidak boleh kosong!')
    else if (!formData.service_uuid) setErrorMessage('Form layanan tidak boleh kosong!')
    else if (!formData.available?.length) setErrorMessage('Form Tersedia tidak boleh kosong!')
    else {
      setErrorMessage('')
      let payload = {
        time: `${formData.start_time} - ${formData.end_time}`,
        day: formData.day,
        available: formData.available,
        service_uuid: formData.service_uuid,
        is_active: formData.is_active
      }
      if (String(user.role[0]).toLowerCase() === 'master_admin') {
        payload.doctor_uuid = uuid
      }
      try {
        if (onEdit) {
          updateScheduleDoctorAuth(payload, idSchedule).then(res => {
            closeModal()
            refetch()
          })
        } else {
          createScheduleDoctorAuth(payload).then(res => {
            closeModal()
            refetch()
          })
        }
      } catch (error) {
        console.log("🚀 ~ file: listScheduleDoctor.js:175 ~ handleSubmit ~ error:", error)
        closeModal()
        swal.error(String(error?.message))
      }
    }
  }

  const handleDelete = async () => {
    try {
      await deleteSchedule(idSchedule).then(res => {
        setFormData({
          start_time: '',
          end_time: '',
          time: '',
          day: '',
          available: [],
          service_uuid: ''
        })

      })
      refetch()
      setIdSchedule(null)
      closeModal()
    } catch (error) {
      refetch()
      setIdSchedule(null)
      closeModal()
    }
  }
  const handleRadioChange = (value) => {
    setFormData({
      ...formData,
      is_active: value === '1' ? true : false
    });
  };


  return (
    <>
      <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8">
        <div className="container mx-auto max-w-full">
        </div>
      </div>

      <div className="px-3 md:px-8 h-full -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <div className="flex flex-row items-center justify-between bg-teal-500 rounded-lg px-7 h-24 shadow-2xl -mt-10">
                <h2 className="text-white text-2xl w-{100} mr-4"> {/* Added 'mr-4' for right margin */}
                  Jadwal
                </h2>
                <Button onClick={() => setModalOpen(true)} className="bg-orange-500 ml-2 mt-1">
                  <Icon name="add" size="3xl" />
                </Button>
              </div>

              <CardBody >
                <div className="overflow-x-auto min-h-[200px] max-h-[100vh] pb-20">
                  <table className="items-center w-full bg-transparent border-collapse h-full">
                    <thead>
                      <tr>
                        <th className="w-1/6 text-teal-500 align-middle border border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-center">
                          Hari
                        </th>

                        <th className="w-1/6 text-teal-500 align-middle border border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-center">
                          Jam
                        </th>
                        <th className="w-1/6 text-teal-500 align-middle border border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-center">
                          Tipe
                        </th>
                        <th className="w-1/6 text-teal-500 align-middle border border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-center">
                          Layanan
                        </th>
                        <th className="w-1/6 text-teal-500 align-middle border border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-center">
                          Status
                        </th>
                        <th className="w-1/6 text-teal-500 align-middle border border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="overflow-y-auto pb-20">
                      {isSuccess && mySchedule?.map((item, index) => {
                        return (
                          <tr key={String(index)}>
                            <th className="w-1/6 border border-gray-200 align-middle font-light text-xs whitespace-nowrap py-4 text-center">
                              <div className="w-16 rounded-full border-2 border-white text-xs">
                                {item?.day}
                              </div>
                            </th>

                            <th className="w-1/6 border border-gray-200 align-middle font-light text-xs whitespace-nowrap py-4 text-center">
                              {item?.time}
                            </th>
                            <th className="w-1/6 border border-gray-200 align-middle font-light text-xs whitespace-nowrap py-4 text-center">
                              <ul>
                                {item?.available.map((service, i) => (
                                  <li key={String(i)}>{service}</li>
                                ))}
                              </ul>
                            </th>
                            <th className="w-1/5 border border-gray-200 align-middle font-light text-xs whitespace-nowrap py-4 text-center">
                              {item?.name?.toUpperCase()}
                            </th>
                            <th className={`w-1/6 border border-gray-200 align-middle font-light text-xs whitespace-nowrap py-4 text-center`} >
                              <div className={!item?.is_active ? "w-2/3 text-red-500" : "w-2/3 text-green-500 font-medium"}>
                                {item?.is_active ? 'AKTIF' : 'TIDAK AKTIF'}
                              </div>
                            </th>

                            <th className="w-1/6 border border-gray-200 align-middle font-light text-xs whitespace-nowrap py-4 text-center">
                              <div className="relative inline-block text-center">
                                <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (itemSelected?.uuid === item?.uuid) {
                                      setIsDropdownOpen(false);
                                    } else {
                                      let times = String(item?.time).split(" - ");
                                      setFormData({
                                        start_time: times[0],
                                        end_time: times[1],
                                        time: item.time,
                                        day: item.day,
                                        available: item.available,
                                        service_uuid: item.service_uuid,
                                        is_active: item.is_active,
                                        profile_uuid: item?.profile_uuid,
                                      });
                                      setItemSelected(item);
                                      setIsDropdownOpen(true);
                                    }
                                  }}
                                  className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-xs font-normal text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-blue-500"
                                  id="table-actions-button"
                                >
                                  Actions
                                </button>
                                </div>

                                {isDropdownOpen && itemSelected?.uuid === item?.uuid ? (
                                  <div
                                    ref={contentRef}
                                    className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="table-actions-button"
                                    tabIndex="-1"
                                  >
                                    <div className="py-1" role="none">
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setIdSchedule(item.uuid);
                                          setOnEdit(false);
                                        }}
                                        className="block px-4 py-2 text-sm hover-bg-gray-100 text-red-500 cursor-pointer"
                                        role="menuitem"
                                        tabIndex="-1"
                                        id="table-action-item-0"
                                      >
                                        HAPUS
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          setIdSchedule(item.uuid);
                                          setOnEdit(true);
                                        }}
                                        className="block px-4 py-2 text-sm hover-bg-gray-100 text-blue-500 cursor-pointer"
                                        role="menuitem"
                                        tabIndex="-1"
                                        id="table-action-item-1"
                                      >
                                        EDIT
                                      </a>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </th>
                            {/* <th className="w-1/6 border border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-center">
                                                            <button onClick={() => handleDelete(item.uuid)} className='bg-red-500 text-white py-2 px-4 rounded-md mt-4'>Delete</button>
                                                        </th> */}
                          </tr>
                        )
                      })}

                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
            {modalOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
                <div className="bg-white p-6  w-80 rounded-lg z-10">
                  <h2 className="text-3xl font-bold mb-6">{onEdit ? 'Edit' : 'Tambah'} Jadwal</h2>

                  <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-semibold mb-2">Waktu Mulai</label>
                    <input
                      required
                      type="time"
                      name="start_time"
                      value={formData.start_time}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded p-3 focus:outline-none focus:borderlue-500"
                    />
                  </div>

                  <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-semibold mb-2">Waktu Berakhir</label>
                    <input required
                      type="time"
                      name="end_time"
                      value={formData.end_time}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded p-3 focus:outline-none focus:borderlue-500"
                    />
                  </div>

                  <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-semibold mb-2">Hari:</label>
                    <select required
                      name="day"
                      value={formData.day}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded p-3 focus:outline-none focus:borderlue-500"
                    >
                      <option value="">Pilih</option>
                      <option value="MONDAY">Monday</option>
                      <option value="TUESDAY">Tuesday</option>
                      <option value="WEDNESDAY">Wednesday</option>
                      <option value="THURSDAY">Thursday</option>
                      <option value="FRIDAY">Friday</option>
                      <option value="SATURDAY">Saturday</option>
                      <option value="SUNDAY">Sunday</option>
                    </select>
                  </div>

                  <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-semibold mb-2">Pilih Layanan</label>
                    <select
                      name="service_uuid"
                      value={formData.service_uuid}
                      onChange={handleChange}
                      required

                      className="w-full border border-gray-300 rounded p-3 focus:outline-none focus:borderlue-500"
                    >
                      <option value="">Pilih</option>
                      {layanan?.data?.data[0]?.layanan?.map((item, i) => (
                        <option key={String(i)} value={item.uuid}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-semibold mb-2">Tersedia:</label>
                    <div className="flex space-x-4">
                      <label className="flex items-center">
                        <input
                          required
                          type="checkbox"
                          name="available"
                          value="TATAP MUKA"
                          checked={formData.available.includes("TATAP MUKA")}
                          onChange={handleChange}
                          className="border border-gray-300 rounded p-2 focus:outline-none focus:borderlue-500"
                        />
                        <span className="ml-2">Tatap Muka</span>
                      </label>

                      <label className="flex items-center">
                        <input
                          required
                          type="checkbox"
                          name="available"
                          value="KONSULTASI ONLINE"
                          checked={formData.available.includes("KONSULTASI ONLINE")}
                          onChange={handleChange}
                          className="border border-gray-300 rounded p-2 focus:outline-none focus:borderlue-500"
                        />
                        <span className="ml-2">Online</span>
                      </label>
                    </div>
                  </div>
                  <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-semibold mb-2">Status:</label>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        id="aktif"
                        name="is_active"
                        value="1"
                        className="mr-2"
                        checked={formData.is_active === true}
                        onChange={() => handleRadioChange('1')}
                      />
                      <label htmlFor="aktif" className="mr-4">
                        Aktif
                      </label>

                      <input
                        type="radio"
                        id="tidakAktif"
                        name="is_active"
                        value="0"
                        className="mr-2"
                        checked={formData.is_active === false}
                        onChange={() => handleRadioChange('0')}
                      />
                      <label htmlFor="tidakAktif" className="mr-2">
                        Tidak Aktif
                      </label>
                    </div>
                  </div>

                  <label className="text-red-500 text-sm font-normal mt-10">{errorMessage}</label>
                  <div className="flex items-center justify-start mt-2">
                    <Button onClick={handleSubmit} className="bg-orange-500 mr-4">
                      {onEdit ? 'Ubah' : 'Buat'}
                    </Button>
                    <Button onClick={closeModal} className="bg-red-500">
                      Batal
                    </Button>
                  </div>

                </div>
              </div>
            )}
          </div>
        </div>
      </div >
    </>
  );
}
