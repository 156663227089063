import React, { useState, useEffect } from 'react';

const Notification = ({ message, type, autoClose, closeTime, onClose }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        if (autoClose) {
            const timeout = setTimeout(() => {
                setVisible(false);
                clearTimeout(timeout);
                onClose();
            }, closeTime);
        }
    }, [autoClose, closeTime, onClose]);

    if (!visible) {
        return null;
    }

    const notificationClasses = `fixed top-0 left-0 w-full p-2 text-white z-50 animate-fade-in ${type === 'success' ? 'bg-green-400' : 'bg-red-400'
        }`;

    return (
        <div className={notificationClasses}>
            <div className="container mx-auto flex items-center justify-between">
                <div className="flex items-center">
                    {type === 'success' && (
                        <svg
                            className="h-6 w-6 text-green-600 mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                            />
                        </svg>
                    )}
                    {type === 'error' && (
                        <svg
                            className="h-6 w-6 text-red-600 mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                            />
                        </svg>
                    )}
                    {message}
                </div>
                <button className="text-lg font-semibold" onClick={onClose}>
                    X
                </button>
            </div>
        </div>
    );
};

export default Notification;
