
import { Button, Card, CardBody, CardHeader, Icon } from '@material-tailwind/react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { createScheduleDoctorAuth, deleteSchedule, getScheduleDoctorByAuth } from '../../services/scheduleService';
import { getListLayanan } from 'services/layananService';
import { getMyLayanan } from '../../services/doctorService';
import { getDetailReservation } from '../../services/reservation';
import { getStorage } from 'utils/auth';
export default function DetailReservasiDoctor(props) {
    let uuid = props?.match?.params?.uuid
    const user = getStorage()

    const { data: myReservation, isFetched, isSuccess, isLoading, refetch } = useQuery(
        ["getDetailReservation"],
        () => getDetailReservation(uuid, String(user?.role?.[0]).toLowerCase() === 'master_admin' ? 'admin' : 'doctor'),
        {
            refetchOnWindowFocus: false,
            retry: 2
        }
    );

    return (
        <>
            {
                isSuccess && myReservation === undefined ? null : (
                    <>
                        <h1>
                            <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
                                <div className="container mx-auto max-w-full">
                                </div>
                            </div>

                            <div className="px-3 md:px-8 h-auto -mt-24">
                                <div className="container mx-auto max-w-full">
                                    <div className="grid grid-cols-1 px-4 mb-16">
                                        <Card>
                                            <CardHeader color="teal" contentPosition="left" className="flex flex-row items-center p-0 m-0">
                                                <div className="flex flex-row items-center justify-between w-full">
                                                    <h2 className="text-white text-2xl w-{100} mr-4"> {/* Added 'mr-4' for right margin */}
                                                        Reservasi
                                                    </h2>
                                                </div>
                                            </CardHeader>


                                            <CardBody>
                                                <h1>Data</h1>
                                            </CardBody>
                                        </Card>

                                    </div>
                                </div>
                            </div></h1>
                    </>)
            }
        </>

    );
}
