import { useDispatch, useSelector } from 'react-redux';
import { listDoctors, registerDoctor } from 'services/doctorService.js';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import TableDoctor from '../../components/TableDoctor.js';

export default function Doctors() {
    const dispatch = useDispatch()
    const doctors = useSelector(state => state.doctor.doctors)


    useEffect(() => {
        dispatch(listDoctors());
        return () => { }
    }, [dispatch]);


    const handleCreateDoctor = () => {
        Swal.fire({
            title: 'Tambah Dokter',
            html: `
            <div style="display: flex; flex-direction: column;">
                <form id="registration-form" style="display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;">
                    <div class="form-group" style="width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center;">
                        
                        <input placeholder="Nama Lengkap" style="padding:0; margin: 10px 0" type="text" id="name" class="swal2-input" required>
                    </div>
                    <div class="form-group" style="width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center;">
                        <input placeholder="Username" style="padding:0; margin: 10px 0" type="text" id="username" class="swal2-input" required>
                    </div>

                    <div class="form-group" style="width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center;">
                        <input placeholder="Email" style="padding:0; margin: 10px 0" type="email" id="email" class="swal2-input" required>
                    </div>
                    <div class="form-group" style="width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center;">
                        <input placeholder="Password" style="padding:0; margin: 10px 0" type="password" id="password" class="swal2-input" required>
                    </div>
                </form>
            </div>
              `,
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: 'Submit',
            preConfirm: () => {
                const emailInput = document.getElementById('email');
                const passwordInput = document.getElementById('password');
                const usernameInput = document.getElementById('username');
                const nameInput = document.getElementById('name');

                const email = emailInput.value;
                const password = passwordInput.value;
                const username = usernameInput.value;
                const name = nameInput.value;

                // Add your validation logic here
                if (name.length < 6) {
                    Swal.showValidationMessage('Name must be at least 6 characters');
                } else if (username.length < 6) {
                    Swal.showValidationMessage('Username must be at least 6 characters');
                } else if (!isValidEmail(email)) {
                    Swal.showValidationMessage('Invalid email address');
                } else if (password.length < 6) {
                    Swal.showValidationMessage('Password must be at least 6 characters');
                }
                // Handle the form data and registration process here
                // You can make an API request to create the user

                return {
                    email,
                    password,
                    username,
                    name,
                };
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Handle the registration success or error here
                const formData = result?.value;
                let response = await registerDoctor(formData)

                if (response?.statusCode === 200) {
                    dispatch(listDoctors())
                }

                console.log("🚀 ~ file: Doctors.js:69 ~ handleCreateDoctor ~ result:", response)
                // Perform the registration logic with formData
                // Show success or error message using Swal
            }
        });
    }
    function isValidEmail(email) {
        // You can use a regular expression for basic email validation
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    }


    return (
        <>
            <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                </div>
            </div>

            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableDoctor data={doctors} handleAdd={handleCreateDoctor} />
                    </div>
                </div>
            </div>
        </>
    );
}
