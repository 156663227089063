// userReducer.js

import { layananTypes } from '../actions/actionTypes';

const initialState = {
    data: [],
    error: null,
};

const layananReducer = (state = initialState, action) => {
    switch (action.type) {
        case layananTypes.LIST_LAYANAN_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
            };
        case layananTypes.LIST_LAYANAN_FAILED:
            return {
                ...state,
                data: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export default layananReducer;  