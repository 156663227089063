
import api from '../utils/api';
import endpoint from '../utils/endpoint';
import { loginSuccess } from '../stores/actions/userAction';
import { getToken } from 'utils/auth';
import { swal } from '../utils/alert';

// Define user-related service functions

export const userLogin = (userData) => {
    return async (dispatch) => {
        if (navigator.onLine) {
            try {
                const response = await api.post(endpoint.user_login, userData);
                console.log("🚀 ~ file: userService.js:16 ~ return ~ response:", response)
                const user = response?.data;
                // Dispatch a success action with the user data
                window.location.replace('/')
                dispatch(loginSuccess(user));
            } catch (error) {
                console.log("🚀 ~ file: userService.js:19 ~ return ~ error:", error)
                if (error?.response?.status === 404) {
                    swal.error('Username atau password salah!')
                } else {
                    swal.error(error?.response?.data?.error?.message || error?.response?.statusText || error?.message)
                }
            }
        } else {
            swal.offline('Periksa kembali koneksi internet anda!')
        }
    };
};


export const checkProfile = async () => {
    try {
        return await api.get(endpoint.check_profile)
    } catch (error) {
        console.log("🚀 ~ file: userServices.js:34 ~ checkProfile ~ error:", error)
    }
}

export const getDoctor = async (uuid) => {
    try {
        return await api.get(endpoint.get_doctor + `/${uuid}`)
    } catch (error) {
        console.log("🚀 ~ file: userService.js:46 ~ getDoctor ~ error:", error)
    }
}


export const updateDoctorByAdmin = async (payload) => {
    console.log("🚀 ~ file: userService.js:53 ~ updateDoctorByAdmin ~ payload:", payload)
    try {
        const response = await api.put(endpoint.admin_update_doctor, payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const getUserProfile = async (userId) => {
    try {
        const response = await api.get(`/users/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const updateProfile = async (payload) => {
    try {
        const response = await api.put(`/profile/v1/update-doctor`, payload, {
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const adminUpdateProfile = async (payload) => {
    try {
        const response = await api.put(`/profile/v1/admin-update`, payload, {
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const uploadImageProfile = async (payload) => {
    console.log(payload, 'payy')
    try {
        const response = await api.post(`/profile/v1/update-image`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + getToken()
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

// export const updateUserProfile = async (userId, userData) => {
//     try {
//         const response = await A
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

export const deleteUser = async (userId) => {
    try {
        const response = await api.delete(`/users/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
