import React from 'react';

const NotFound = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-200">
            <div className="max-w-md p-6 space-y-4 text-center bg-white rounded-lg shadow-md">
                <img src={require('../assets/img/not-found.svg').default} alt="Not Found" className="mx-auto" />
                <h1 className="text-3xl font-semibold text-gray-800">404 - Page Not Found</h1>
                <p className="text-gray-600">The page you're looking for does not exist.</p>
            </div>
        </div>
    );
};

export default NotFound;
