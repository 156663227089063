import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk'; // Optional, for async actions
import user from './reducers/userReducer'
import doctor from './reducers/doctorReducer'
import layanan from './reducers/layananReducer'
// Define your reducers
const rootReducer = combineReducers({
    user,
    doctor,
    layanan
    // Your individual reducers go here
});

// Create the Redux store
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;