import { userTypes } from "./actionTypes";

export const registerSuccess = (user) => ({
    type: userTypes.REGISTER_SUCCESS,
    payload: user,
});

export const registerFailed = (error) => ({
    type: userTypes.REGISTER_FAILED,
    error,
});

export const loginSuccess = (user) => ({
    type: userTypes.LOGIN_SUCCESS,
    payload: user,
});

export const loginFailed = (error) => ({
    type: userTypes.LOGIN_FAILED,
    error,
});

export const logoutSuccess = () => ({
    type: userTypes.LOGOUT_SUCCESS,
});
export const setProfile = (user) => ({
    type: userTypes.SET_PROFILE,
    payload: user
});
export const setDoctorUuid = (uuid) => ({
    type: userTypes.SET_DOCTER_UUID,
    payload: uuid
});