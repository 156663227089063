// userReducer.js

import { doctorTypes } from '../actions/actionTypes';

const initialState = {
    doctors: [],
    error: null,
};

const doctorReducer = (state = initialState, action) => {
    switch (action.type) {
        case doctorTypes.LIST_DOCTORS_SUCCESS:
            return {
                ...state,
                doctors: action.payload,
                error: null,
            };
        case doctorTypes.LIST_DOCTORS_FAILED:
            return {
                ...state,
                doctors: [],
                error: action.error,
            };
        default:
            return state;
    }
};

export default doctorReducer;  