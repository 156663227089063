import React, { useState } from 'react';
import colors from '../../assets/styles/colors.js';
import { Link } from 'react-router-dom';
import { userLogin } from '../../services/userService.js';
import { useDispatch } from 'react-redux';
import { Notification } from '../../components/index.js'

const LoginPage = () => {
    const dispatch = useDispatch()
    // const error = useSelector(state => state.user.error)
    const [username, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [notification, setNotification] = useState(null);

    // useEffect(() => {
    //     if (error?.message) {
    //         console.log("🚀 ~ file: Login.js:18 ~ useEffect ~ error?.message:", error?.message)
    //         alert(error?.message)
    //     }
    //     return () => { }
    // }, [error?.message])

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (username && password) {

            let credentials = {
                "username": username,
                "password": password
            };

            dispatch(userLogin(credentials));
        } else {
            if (username?.length < 5) throw alert('Username must be at least 5 characters')
            if (password?.length < 6) throw alert('Password must be at least 6 characters')
        }
    };

    const backgroundStyle = {
        background: 'linear-gradient(to bottom, #C9EBFA, #FFFFFF)',
    };

    const formStyle = {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    };

    const buttonStyle = {
        backgroundColor: colors.primary,
        borderRadius: '0.375rem', // Rounded corners
        padding: '0.5rem 1rem', // Adjust padding
        cursor: 'pointer', // Change cursor on hover
    };

    const closeNotification = () => {
        setNotification(null);
    };

    return (
        <div className="min-h-screen flex items-center justify-center" style={backgroundStyle}>
            <div className="bg-cyan-400 p-8 rounded-lg shadow-md w-full sm:w-96" style={formStyle}>
                <h2 className="text-2xl font-semibold mb-4 text-center" style={{ color: colors.primary }}>Login</h2>
                <form onSubmit={handleLogin}>
                    <div className="mb-4">
                        <label htmlFor="username" className="block font-medium mb-2" style={{ color: colors.primary }}>
                            Username
                        </label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            minLength={5}
                            maxLength={50}
                            value={username}
                            onChange={handleEmailChange}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-cyan-300"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block font-medium mb-2" style={{ color: colors.primary }}>
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            minLength={6}
                            maxLength={50}
                            value={password}
                            onChange={handlePasswordChange}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-cyan-300"
                        />
                    </div>
                    <div className="flex justify-around items-center">
                        <button
                            type="submit"
                            className="hover:bg-cyan-600 text-white px-4 py-2 rounded-lg transition-colors duration-300 mr-2 w-24"
                            style={buttonStyle}>
                            Login
                        </button>
                        <button
                            type="button"
                            className="hover:bg-cyan-200 text-white px-4 py-2 rounded-lg transition-colors duration-300 w-24"
                            style={buttonStyle}
                        >
                            <Link to="/register" className="hover:underline" style={{ color: 'white', textDecoration: 'none' }}>Register</Link>
                        </button>
                    </div>
                    <div className="text-center mt-4">
                        <Link to="/forgot-password" className="hover:underline" style={{ color: colors.primary }}>Forgot Password</Link>
                    </div>
                </form>
            </div>

            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    autoClose={true}
                    closeTime={3000}
                    onClose={closeNotification}
                />
            )}
        </div>
    );
};

export default LoginPage;