import React from 'react'
import Swal from 'sweetalert2';


const swalAlert = (title, message) => {
    if (title === 'network!') {
        Swal.fire({
            title: "Koneksi Terputus!",
            text: "Mohon periksa kembali koneksi internet Adna..",
            icon: "question"
        });
    } else if (title === 'remove') {
        return Swal.fire({
            title: "Hapus!",
            text: "Kamu yakin akan menghapus ini?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Hapus!"
        }).then((result) => {
            console.log("🚀 ~ file: alert.js:22 ~ swalAlert ~ result:", result)
            if (result.isConfirmed) return true
            else return false
        }).catch(() => {
            return false
        })
    } else {
        Swal.fire({
            title: title === "success" ? "Success!" : "Failed!",
            text: message,
            icon: title === "success" ? "success" : "error",
            timer: title === "success" ? 1500 : null,
            showConfirmButton: title === "success" ? false : true,
        });
    }
}

const success = (message) => swalAlert("success", message)
const error = (message) => swalAlert("failed", message)
const offline = (message) => swalAlert("network", message)
const remove = (message) => swalAlert("remove", message)

export const swal = {
    success,
    error,
    offline,
    remove
};